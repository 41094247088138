import { Route, Routes } from 'react-router-dom'
import { Home, PrivacyPage, ErrorPage, SettingsPage } from '../pages'

export const AppRoutes = () => {
  return (
    <Routes>
      <Route path='*' element={<Home />} errorElement={<ErrorPage />} />
      <Route path='/privatumas' element={<PrivacyPage />} errorElement={<ErrorPage />} />
      <Route path='/nustatymai' element={<SettingsPage />} errorElement={<ErrorPage />} />

      {/* <Route path='*' element={<Wrapped404Page />} /> */}
    </Routes>
  )
}
