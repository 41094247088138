import { Paper, Typography } from '@mui/material'
import { WrappedMenuAndBarPage } from '../wrappers'

export const PrivacyPage = () => {
  return (
    <WrappedMenuAndBarPage>
      <Paper elevation={3} sx={{ margin: 'auto', maxWidth: '1000px', textAlign: 'center', borderRadius: '10px' }}>
        <Typography variant='h1'>Privatumo politika</Typography>
        <Typography variant='h6'>
          Mes nekaupiame jūsų duomenų, nesaugome dokumentų. Mes tik stebime bendrą kasdieninių naudotojų skaičių ir
          skaičiuojame dieninius duomenų perdavimo statistikos rodiklius. Tai reiškia, kad mes galime matyti bendrą
          sėkmingų ir nesėkmingų failų perdavimo skaičių. Taip galime greitai aptikti klaidas ar problemas ir skubiai
          jas ištaisyti. Nuskaitymas visiškai atitinka GDPR (Bendroji duomenų apsaugos reglamentacija), nes jūsų
          duomenys niekur nėra saugomi. Privatumas ir saugumas yra pagrindiniai šios platformos tikslai. Jei vis dar
          turite kokių nors abejonių dėl privatumo, nedvejodami atsiųskite mums žinutę.
        </Typography>
      </Paper>
    </WrappedMenuAndBarPage>
  )
}
