import * as React from 'react'
import Drawer from '@mui/material/Drawer'
import List from '@mui/material/List'
import IconButton from '@mui/material/IconButton'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew'
import { MenuItems } from './menuItems'
import { useNavigate } from 'react-router-dom'

import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'

export const SideMenu = () => {
  const [open, setOpen] = React.useState<boolean>(false)
  const navigate = useNavigate()

  const handleDrawerOpen = () => {
    setOpen(true)
  }

  const handleDrawerClose = () => {
    setOpen(false)
  }

  const handleNavigate = (path: string) => {
    navigate(path)
  }

  const DrawerStyle =
    window.innerWidth > 600
      ? {
          width: '15rem',
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: '15rem',
            boxSizing: 'border-box',
            height: '30rem',
            top: '7rem',
            borderRadius: '10% 20% 20% 10%',
          },
        }
      : {
          width: '10rem',
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: '15rem',
            boxSizing: 'border-box',
            height: '30rem',
            top: '15vh',
            borderRadius: '10% 20% 20% 10%',
          },
        }

  return (
    <>
      {open ? (
        <IconButton
          sx={{ position: 'sticky', top: '20rem', left: '180px', zIndex: 1201 }}
          aria-label='menu'
          onClick={handleDrawerClose}
        >
          <ArrowBackIosNewIcon sx={{ fontSize: '2.5rem' }} />
        </IconButton>
      ) : (
        <IconButton
          sx={{ position: 'sticky', top: '20rem', zIndex: 1201 }}
          aria-label='menu'
          onClick={handleDrawerOpen}
        >
          <ArrowForwardIosIcon sx={{ fontSize: '2.5rem' }} />
        </IconButton>
      )}
      <Drawer sx={DrawerStyle} variant='persistent' anchor='left' open={open}>
        <List>
          {MenuItems.map((item) => (
            <ListItem
              key={item.name}
              disablePadding
              sx={{ marginTop: '1rem' }}
              onClick={() => handleNavigate(item.path)}
            >
              <ListItemButton>
                <ListItemIcon>{item.icon}</ListItemIcon>
                <ListItemText primary={item.name} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </Drawer>
    </>
  )
}
