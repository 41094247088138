import { AppContainer } from '../../components'
import { AppRoutes } from '../../routes'

export const App = () => {
  return (
    <AppContainer>
      <AppRoutes />
    </AppContainer>
  )
}
