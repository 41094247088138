import ShieldOutlinedIcon from '@mui/icons-material/ShieldOutlined'
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined'

export const MenuItems = [
  {
    name: 'Privatumas',
    icon: <ShieldOutlinedIcon />,
    path: '/privatumas',
  },
  {
    name: 'Nustatymai',
    icon: <SettingsOutlinedIcon />,
    path: '/nustatymai',
  },
]
