import { AppBar, Box, Toolbar, Typography } from '@mui/material'
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined'

import { theme } from '../theme'

interface appBarProps {
  title?: string
}

export const CustomAppBar = (props: appBarProps) => {
  const { title } = props
  const defaultTitle = 'Skenuok nuotraukas ir failus. Gauk tekstą tiesiogiai iš nuotraukų'

  //   const [dialogOpen, setDialogOpen] = useState(false)

  return (
    <Box sx={{ zIndex: 100, flexGrow: 1, position: 'sticky', top: '0px' }} aria-label='AppBar' data-testid='app-bar'>
      <AppBar
        position='sticky'
        color='default'
        elevation={5}
        sx={{ backgroundColor: theme.palette.grey[50], maxHeight: '100px' }}
      >
        <Toolbar>
          {/* {logo} */}
          <>
            <HomeOutlinedIcon
              sx={{ fontSize: '2.0rem' }}
              onClick={() => {
                document.location.href = '/'
              }}
            />
            <Typography
              variant={window.innerWidth > 600 ? 'h1' : 'h3'}
              textAlign='center'
              fontFamily='cursive'
              margin='auto'
            >
              {title ? title : defaultTitle}
            </Typography>
            {/* <IconButton
              size='large'
              edge='start'
              color='inherit'
              aria-label='menu'
              sx={{ mr: 3, padding: 'unset', height: '3.9vh' }}
              onClick={() => setDialogOpen((prevState) => !prevState)}
            >
              <ProfileAvatar />
            </IconButton>
            {dialogOpen ? <ProfileDialog /> : null} */}
          </>
        </Toolbar>
      </AppBar>
    </Box>
  )
}
