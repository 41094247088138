const makeFullUrl = (url: string) => {
  return `${process.env.REACT_APP_BACKEND_URL}/${url}`
}

export const postFiles = async (url: string, files: File[]) => {
  const data = new FormData()

  for (const file of files) {
    data.append('files', file, file.name)
  }

  const response = await fetch(makeFullUrl(url), {
    method: 'POST',
    body: data,
    headers: {
      'x-functions-key': `${process.env.REACT_APP_FUNCTION_KEY}`,
    },
  })

  const jsonData = await response.json()

  return jsonData
}

export const getRequest = async (url: string) => {
  const response = await fetch(makeFullUrl(url), {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'x-functions-key': `${process.env.REACT_APP_FUNCTION_KEY}`,
    },
  })

  const jsonData = await response.json()

  return jsonData
}

export const sendEmail = async (url: string, name: string, email: string, message: string) => {
  const data = {
    name: name,
    email: email,
    message: message,
  }

  const response = await fetch(makeFullUrl(url), {
    method: 'POST',
    body: JSON.stringify(data),
    headers: {
      'x-functions-key': `${process.env.REACT_APP_FUNCTION_KEY}`,
      'Content-Type': 'application/json',
    },
  })

  const jsonData = await response.json()

  return jsonData
}
