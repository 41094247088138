import { useRouteError } from 'react-router-dom'
import { WrappedMenuAndBarPage } from '../wrappers'

type ErrorObj = {
  statusText?: string
  message?: string
}

export const ErrorPage = () => {
  const error = useRouteError() as ErrorObj

  return (
    <>
      <WrappedMenuAndBarPage>
        <div id='error-page'>
          <h1>Oops!</h1>
          <p>Sorry, an unexpected error has occurred.</p>
          <p>
            <i>{error.statusText || error.message}</i>
          </p>
        </div>
      </WrappedMenuAndBarPage>
    </>
  )
}
