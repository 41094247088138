import { useState } from 'react'
import { TextField, Button, Typography, Box } from '@mui/material'
import { sendEmail } from '../../utils/requests'

export const ContactForm = () => {
  const [name, setName] = useState<string>('')
  const [email, setEmail] = useState<string>('')
  const [message, setMessage] = useState<string>('')
  const [emailSend, setEmailSend] = useState<boolean>(false)

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault()
    setEmailSend(true)
    if (message.length > 20) {
      try {
        await sendEmail('email/', name, email, message)
      } catch (error) {
        console.log(error)
      }
    }
  }

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '30%',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          maxWidth: 600,
          mx: 'auto',
          p: 2,
          border: '2px solid  #000000',
          borderRadius: '12px',
          boxShadow: 1,
        }}
      >
        {emailSend ? (
          <Typography variant='h4' align='center' mb={2}>
            Jūsų laiškas sėkmingai išsiųstas!
          </Typography>
        ) : (
          <>
            <Typography variant='h4' align='center' mb={2}>
              Susisiekite su mumis dėl integracijos į jusų sistemą
            </Typography>
            <form onSubmit={handleSubmit}>
              <TextField
                fullWidth
                label='Pilnas vardas'
                value={name}
                onChange={(e) => setName(e.target.value)}
                margin='normal'
                required
              />
              <TextField
                fullWidth
                label='El. paštas'
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                margin='normal'
                required
                type='email'
              />
              <TextField
                fullWidth
                label='Žinutė'
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                margin='normal'
                required
                multiline
                rows={4}
              />
              <Button
                fullWidth
                type='submit'
                sx={{
                  mt: 2,
                  backgroundColor: '#000',
                  color: '#fff',
                  '&:hover': {
                    backgroundColor: '#111',
                  },
                }}
              >
                Siųsti
              </Button>
            </form>
          </>
        )}
      </Box>
    </Box>
  )
}
