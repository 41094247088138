import { useState, useEffect } from 'react'
import { Typography, Box, FormControl, FormLabel, FormGroup, FormControlLabel, Checkbox } from '@mui/material'
import { WrappedMenuAndBarPage } from '../wrappers'

export const SettingsPage = () => {
  const [format, setFormat] = useState({
    text: true,
    json: false,
  })

  const defaultFormatValues = {
    text: false,
    json: false,
  }

  useEffect(() => {
    const formatSettings = sessionStorage.getItem('formatSettings')
    console.log(formatSettings, 'cccc')
    if (!formatSettings) {
      sessionStorage.setItem('formatSettings', JSON.stringify(format))
    } else {
      setFormat(JSON.parse(formatSettings))
    }
  }, [])

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const changedValues = { ...defaultFormatValues, [event.target.name]: event.target.checked }
      setFormat({ ...changedValues })
      sessionStorage.setItem('formatSettings', JSON.stringify(changedValues))
    }
  }

  return (
    <WrappedMenuAndBarPage>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: '30%',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            maxWidth: 600,
            minWidth: 400,
            mx: 'auto',
            p: 2,
            border: '2px solid  #000000',
            borderRadius: '12px',
            boxShadow: 1,
          }}
        >
          <Typography variant='h4' align='center' mb={2}>
            Nustatymai
          </Typography>
          <FormControl sx={{ m: 3 }} component='fieldset' variant='standard'>
            <FormLabel component='legend'>Pasirinkite rezultatų formatą:</FormLabel>
            <FormGroup>
              <FormControlLabel
                control={<Checkbox checked={format.text} onChange={handleChange} name='text' />}
                label='Tekstas'
              />
              <FormControlLabel
                control={<Checkbox checked={format.json} onChange={handleChange} name='json' />}
                label='JSON'
              />
            </FormGroup>
          </FormControl>
        </Box>
      </Box>
    </WrappedMenuAndBarPage>
  )
}
