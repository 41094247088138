import { useDropzone } from 'react-dropzone'
import { useMemo, useState, useCallback, useEffect } from 'react'
import {
  Container,
  Button,
  ImageList,
  ImageListItem,
  ImageListItemBar,
  CircularProgress,
  Backdrop,
  Typography,
  Input,
} from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete'
import { postFiles } from '../../utils/requests'
import { ContactForm } from '../../components'
import { WrappedMenuAndBarPage } from '../wrappers'

const baseStyle = {
  flex: 1,
  display: 'flex',
  alignItems: 'center',
  padding: '20px',
  borderWidth: 2,
  borderRadius: 2,
  borderColor: '#eeeeee',
  borderStyle: 'dashed',
  backgroundColor: '#fafafa',
  color: '#bdbdbd',
  outline: 'none',
  transition: 'border .24s ease-in-out',
  marginTop: '30px',
}

const acceptStyle = {
  borderColor: '#00e676',
}

const rejectStyle = {
  borderColor: '#ff1744',
}

type FilePreview = File & { preview: string }

export const Home = () => {
  const [selectedImages, setSelectedImages] = useState<FilePreview[]>([])
  const [loading, setLoading] = useState<boolean>(false)
  const [imageData, setImageData] = useState<[]>([])

  // clean up
  useEffect(
    () => () => {
      selectedImages.forEach((file) => URL.revokeObjectURL(file.preview))
    },
    [selectedImages],
  )

  const onDrop = useCallback((acceptedFiles: File[]) => {
    setSelectedImages(
      acceptedFiles.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        }),
      ),
    )
  }, [])

  const { getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject } = useDropzone({
    onDrop,
    accept: {
      'image/*': ['.png', '.gif', '.jpeg', '.jpg', '.pdf'],
    },
  })

  const someStyle = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isDragAccept, isDragReject],
  )

  const uploadFiles = async () => {
    if (selectedImages) {
      setLoading(true)

      try {
        const imageData = await postFiles('uploadFile/', selectedImages)
        setImageData(imageData)
      } catch (error) {
        console.log(error)
        setImageData([['Error occured in parsing data']] as unknown as [])
      }
      setLoading(false)
    }
  }

  const removeImage = (file: FilePreview) => {
    setSelectedImages((current) => {
      return current.filter((fileObj) => file.name != fileObj.name)
    })
    setImageData([])
  }

  return (
    <>
      <WrappedMenuAndBarPage>
        <Container>
          <div {...getRootProps({ style: { ...someStyle } })}>
            <input {...getInputProps()} />
            {isDragActive ? (
              <p>Drop file(s) here ...</p>
            ) : (
              <p style={{ textAlign: 'center' }}>
                Paspauskite ir įkelkite failus... (Laikydami ctrl pasirinkite daugiau nei vieną failą)
              </p>
            )}
          </div>
          <ImageList sx={{ width: 500, height: 300, marginLeft: 'auto', marginRight: 'auto' }} cols={3} rowHeight={150}>
            {selectedImages.map((item) => (
              <ImageListItem key={item.name}>
                <img src={item.preview} alt={item.name} loading='lazy' />
                <ImageListItemBar
                  sx={{
                    background:
                      'linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, ' + 'rgba(0,0,0,0.3) 10%, rgba(0,0,0,0) 100%)',
                  }}
                  position='top'
                  actionIcon={
                    <DeleteIcon
                      onClick={() => {
                        removeImage(item)
                      }}
                    />
                  }
                />
              </ImageListItem>
            ))}
          </ImageList>
          {selectedImages.length > 0 && (
            <Button
              variant='outlined'
              onClick={uploadFiles}
              style={{ margin: '0 auto', display: 'block', fontSize: '25px', marginBottom: '50px' }}
            >
              Nuskaityti!
            </Button>
          )}
          <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={!!loading}
            // onClick={handleClose}
          >
            <CircularProgress color='inherit' />
          </Backdrop>
          {!!imageData.length && (
            <Input
              multiline
              value={imageData}
              readOnly
              sx={{
                display: 'block',
                textAlign: 'justify',
                textAlignLast: 'center',
                marginBottom: '5%',
                // border: '1px solid rgba(0, 0, 0, 0.42)',
                backgroundColor: 'rgb(250, 250, 250)',
                fontSize: '15px',
              }}
            />
            // {imageData.map((file: []) => {
            //   return file.map((items: []) => {
            //     return JSON.stringify(items)
            //   })
            // })}
          )}
          <ContactForm />
          <Typography textAlign='center' margin='10px'>
            &copy; 2023 Eudemonia
          </Typography>
        </Container>
      </WrappedMenuAndBarPage>
    </>
  )
}
