import React from 'react'
import { CustomAppBar, SideMenu } from '../../components'

interface WrapperProps {
  children?: React.ReactNode
}

export const WrappedMenuAndBarPage = (props: WrapperProps) => {
  return (
    <>
      <CustomAppBar />
      <SideMenu />
      {props.children}
    </>
  )
}
